
export default {
    limitStringLength (str, maxLength = 210, ending = '') {
        if (str.length <= maxLength) { return str }
        return str.slice(0, maxLength) + ending
    },
    plural (num, { one, few, many }) {
        if (typeof num !== 'string') {
            num = `${num}`
        }
        num = num.slice(-2)
        const lastSym = parseInt(num.substr(-1))
        if (lastSym === 1 && num !== '11') { return one }
        if ([2, 3, 4].includes(lastSym) && !['12', '13', '14'].includes(num)) { return few }
        return many
    },
    formatNumber (num) {
        num = num.toString().split('')
        let i = 0
        return num.reverse().map((n) => {
            i++
            const result = i === 3 ? ` ${n}` : n
            if (i === 3) { i = 0 }
            return result
        }).reverse().join('').trim()
    },
    transliterate (word, unknownSymbol = '_') {
        let answer = "",
            a = {};
        a["Ё"]="YO";a["Й"]="I";a["Ц"]="TS";a["У"]="U";a["К"]="K";a["Е"]="E";a["Н"]="N";a["Г"]="G";a["Ш"]="SH";a["Щ"]="SCH";a["З"]="Z";a["Х"]="H";a["Ъ"]="'";
        a["ё"]="yo";a["й"]="i";a["ц"]="ts";a["у"]="u";a["к"]="k";a["е"]="e";a["н"]="n";a["г"]="g";a["ш"]="sh";a["щ"]="sch";a["з"]="z";a["х"]="h";a["ъ"]="'";
        a["Ф"]="F";a["Ы"]="I";a["В"]="V";a["А"]="A";a["П"]="P";a["Р"]="R";a["О"]="O";a["Л"]="L";a["Д"]="D";a["Ж"]="ZH";a["Э"]="E";
        a["ф"]="f";a["ы"]="i";a["в"]="v";a["а"]="a";a["п"]="p";a["р"]="r";a["о"]="o";a["л"]="l";a["д"]="d";a["ж"]="zh";a["э"]="e";
        a["Я"]="Ya";a["Ч"]="CH";a["С"]="S";a["М"]="M";a["И"]="I";a["Т"]="T";a["Ь"]="'";a["Б"]="B";a["Ю"]="YU";
        a["я"]="ya";a["ч"]="ch";a["с"]="s";a["м"]="m";a["и"]="i";a["т"]="t";a["ь"]="'";a["б"]="b";a["ю"]="yu";
    
        for (let i = 0; i < word.length; i++)
        {
            if (word[i]) {
                if (a[word[i]] === undefined) {
                    answer += unknownSymbol;
                } else {
                    answer += a[word[i]];
                }
            }
       }
       return answer;
    }
}
