<template>
    <div class="d-flex flex-column w-100">
        <div class="d-flex my-5">
            <v-spacer />
            <v-btn
                small
                outlined
                :loading="loading"
                :disabled="loading"
                @click="requestAudit"
            >Аудит базы данных</v-btn>
        </div>
        <div class="d-flex flex-row align-center">
            <span>Фильтр по категориям</span>
            <v-select
                v-model="filter.section"
                :items="mediaSections"
                label="Раздел"
                :disabled="loading"
                class="ml-5"
            ></v-select>
            <template v-if="filter.section === 'tasks'">
                <v-select
                    v-model="filter.year"
                    :items="years"
                    label="Год"
                    :disabled="loading"
                    class="ml-5"
                ></v-select>
                <v-select
                    v-model="filter.subject"
                    :items="subjects"
                    label="Предмет"
                    :disabled="loading"
                    class="ml-5"
                ></v-select>
                <v-select
                    v-model="filter.grade"
                    :items="grades"
                    label="Класс"
                    :disabled="loading"
                    class="ml-5"
                ></v-select>
            </template>
        </div>
        <ml-manager
            :key="path"
            :files="filesToShow"
            :loading="loading"
            :path="path"
            :showUploadMoreButton="showUploadMoreButton"
            disable-select
            remove-available
            class="w-100"
            @onFileSelect="returnPath"
            @fileRemoved="excludeFile"
            @uploaded="fetchFiles"
            @scroll-ended="fetchFiles"
            @add-new-items-to-list="addNewItemsToList"
            @search="onSearch"
        />
    </div>
</template>

<script>
import ApiHelper from '@/helpers/ApiHelper.js'
import mlManager from '@/components/inputs/media-library/ml-manager'
import StringHelper from '@/plugins/string'

export default {
    components: { mlManager },
    data () {
        return {
            loading: false,
            files: [],
            foundItems: [],
            lastSearchPhrase: '',
            showUploadMoreButton: true,
            mediaSections: [{ text: 'Задания', value: 'tasks' }, { text: 'Инструкции', value: 'instructions' }],
            filter: {
                section: 'tasks',
                year: null,
                subject: null,
                grade: null
            }
        }
    },
    computed: {
        origin () {
            return window.location.origin
        },
        filesToShow () {
            return this.lastSearchPhrase ? this.foundItems : this.files
        },
        years () {
            const curYear = (new Date()).getFullYear()
            const startYear = 2022
            const result = []
            let year = startYear
            do {
                result.push(year++)
            } while (year <= curYear)
            return result
        },
        grades () {
            return (new Array(11)).fill(null).map((item, index) => index + 1)
        },
        subjects () {
            return this.$store.state.job.subjects
                .map(s => ({ text: s.text, value: StringHelper.transliterate(s.value)?.toLowerCase() }))
        },
        path () {
            if (this.filter.section === 'instructions') {
                return `instructions`
            }
            else if (this.filter.section === 'tasks' && this.filter.year && this.filter.subject && this.filter.grade) {
                return `${this.filter.year}/${(typeof this.filter.subject === 'object' ? this.filter.subject.value : this.filter.subject)}/${this.filter.grade}`
            } else {
                return null
            }
        }
    },
    watch: {
        path () {
            this.initialFetch()
        }
    },
    created () {
        this.filter.year = this.years[this.years.length - 1]
        this.filter.subject = this.subjects[0]
        this.filter.grade = this.grades[0]
        this.initialFetch()
    },
    methods: {
        returnPath (val) {
            this.$emit('input', val)
        },
        async initialFetch () {
            if (!this.path) { return false }
            this.files = []
            // Делаем первый общий запрос
            await this.fetchFiles()
        },
        async onSearch (title) {
            this.lastSearchPhrase = title

            if (!title) { return }
            const items = []

            const results = await Promise.all([
                this.$store.dispatch('media_library/search', { title, path: this.path }), // search by title
                this.$store.dispatch('media_library/search', { path: `${this.path}/${title}` }) // search by path
            ])

            results.forEach(result => {
                if (result.error) {
                    console.error(result.error)
                    return
                }
                items.push(...result.data.items.map(item => {
                        item.id = parseInt(item.id)
                        return item
                    })
                )
            })
            this.foundItems = _.uniqBy(items, 'id')
            
            // Объединяем массивы, чтобы найденные данные попали this.files
            this.addNewItemsToList(this.foundItems)
        },
        // Добавляем только уникальные файлы в список
        addNewItemsToList (items) {
            let hasChanges = false

            const filesGroups = [ this.files ]
            this.lastSearchPhrase && filesGroups.push(this.foundItems)

            for (const item of items) {
                for (const filesGroup of filesGroups) {
                    const foundCopy = filesGroup.find(file => file.id === item.id)
                    if (!foundCopy) {
                        item.id = parseInt(item.id)
                        filesGroup.push(item)
                        hasChanges = true
                    }
                }
            }
            return hasChanges
        },
        excludeFile (id) {
            this.files = this.files.filter(file => file.id !== id)
            if (this.lastSearchPhrase) {
                this.foundItems = this.foundItems.filter(file => file.id !== id)
            }
        },
        async fetchFiles () {
            if (this.loading) { return }
            this.loading = true

            try {
                const response = await this.$store.dispatch('media_library/media', {
                    limit: 20,
                    offset: this.files.length,
                    path: this.path
                });
                if (response.success) {
                    const isDataUpdated = this.addNewItemsToList(response.data.items)
                    this.showUploadMoreButton = isDataUpdated
                } else {
                    console.error('Ошибка загрузки данных медиа-библиотеки.')
                }
            } catch (e) {
                console.error(e)
            } finally {
                this.loading = false
            }
        },
        async requestAudit () {
            if (this.loading) { return }
            const userAnswer = confirm('Запуск аудита автоматически удалит записи из базы данных, которые имеют некорректные пути к файлам; а так же добавит в базу данных те файлы, что есть на сервере, но не учтены.')
            if (!userAnswer) { return }
            let result = null
            try {
                this.loading = true
                const { success } = await ApiHelper.post('/api/media-library/data-base-audit')
                result = !!success
            } catch (e) {
                console.error(e)
                result = false
            }
            this.loading = false
            alert(result ? 'Аудит выполнен успешно!' : 'Аудит прерван ошибкой.')
            this.initialFetch()
        }
    }
}
</script>